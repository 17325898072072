@use "../abstracts";

.technology {
    &__left {
        display: flex;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        position: relative;
        padding-top: 56.25%;
        height: 100%;
    }

    &__thumbs {
        display: flex;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        position: relative;
        padding-top: 100%;
        height: 100%;
    }
    &__right {
        padding: 20px;
        border-radius: 10px;
        background: var(--bg-product-info);
        @include abstracts.screen(lg) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
    &__doc {
        padding: 8px 12px;
        margin-top: 20px;
        display: flex;
        column-gap: 16px;
        width: 100%;
        border-radius: 50px;
        font-size: 2rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        background: var(--bg-primary);
        color: var(--color-primary);
        transition: 0.3s linear;
        &:hover {
            background: var(--color-white);
            color: var(--color-primary);
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        height: 100%;
        padding: 16px;
        border-radius: 10px;
        gap: 10px;
        background: var(--bg-product-info);
    }
    &__heading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 138.462%;
    }
    &__desc {
        font-size: 1.5rem;
        text-align: center;
        line-height: 138.462%;
    }
    &__thumbs-video {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            position: absolute;
            content: "";
            inset: 0;
            background-color: rgba(0, 0, 0, 0.1);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
        &-icon {
            position: absolute;
            font-size: 30px;
            color: var(--color-white);
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
