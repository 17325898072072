html {
    // ==== Common ====
    --text-color: #000;
    --text-title: #002249;
    --bg-primary: #e5f1fe;
    --color-primary: #226fe9;
    --color-white: #fff;
    --color-black: #000;
    --white-color: #fff;
    --home-separate: var(--color-primary);
    --primary-color: #226fe9;
    --border-primary: #226fe9;
    --box-shadow: 0 0px 2px 0 rgba(60, 64, 67, 0.1),
        0 0px 6px 0px rgba(60, 64, 67, 0.25);
    // ======  Header ======
    --header-bg-color: #fff;
    --header-shadow: 2px 2px 7px #ccc;
    --link-header: var(--text-color);
    --bg-dropdown: #fcfcfc;
    --navbar-height: 64px;
    --sidebar-bg: #fff;
    // Product
    --product-card-shadow: rgba(237, 237, 246);
    --product-card-bg: #fff;
    --bg-product-info: #f6f6f6;
    --color-brand: #9e9da8;
}
