@use "../abstracts";

.about {
    padding: 40px 0;
    background-color: #f5f6f6;
    & .section-heading {
        position: relative;
        left: 10px;
    }
    &__wrapper {
        margin-top: 50px !important;
        margin-left: -2px !important;
        margin-right: -2px !important;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        @include abstracts.screen(lg) {
            margin-top: 30px !important;
            display: flex;
            gap: 0px;
        }
        @include abstracts.screen(sm) {
            margin-left: initial !important;
            margin-right: initial !important;
        }
    }
    &__white {
        background-color: var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }
    &__item {
        position: relative;
        z-index: 0;
        &:first-of-type {
            grid-area: 1 / 1 / 2 / 5;
            & .about__img {
                width: 100%;
                height: 100%;
                &-thumb {
                    width: 100%;
                    height: 696px;
                    object-fit: cover;
                    display: flex;
                }
            }
        }
        &:nth-of-type(2) {
            grid-area: 1 / 5 / 2 / 7;
        }
        &:nth-of-type(3) {
            grid-area: 2 / 1 / 3 / 3;
        }
        &:nth-of-type(4) {
            grid-area: 2 / 3 / 3 / 5;
        }
        &:last-of-type {
            grid-area: 2 / 5 / 3 / 7;
        }
        &:hover::before,
        &:hover::after {
            width: 50%;
            height: 50%;
            background: var(--primary-color);
        }
        &:hover .about__img-thumb {
            scale: 1.1;
        }
        &::before {
            content: "";
            position: absolute;
            right: -2px;
            top: -2px;
            height: 4px;
            width: 0px;
            transition: all 0.5s ease 0s;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            left: -2px;
            bottom: -2px;
            height: 4px;
            width: 0px;
            transition: all 0.5s ease 0s;
            z-index: -1;
        }
        @include abstracts.screen(lg) {
            &:hover::after,
            &:hover::before {
                content: initial;
            }
            &:hover .about__img-thumb {
                scale: 1;
            }
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        &-thumb {
            width: 100%;
            height: 696px;
            object-fit: cover;
            display: flex;
            transition: all 0.5s ease 0s;
        }
    }
    &__content {
        position: absolute;
        bottom: 0;
        padding: 60px 24px 24px;
        display: flex;
        justify-content: end;
        flex-direction: column;
        width: 100%;
        z-index: 1;
        height: 40%;
        @include abstracts.screen(lg) {
            height: auto;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000000 50%
            );
            z-index: -1;
        }
    }
    &__title {
        font-size: 2.6rem;
        color: var(--white-color);
        margin-bottom: 4px;
    }
    &__desc {
        --line-clamp: 3;
        font-size: 1.6rem;
        color: var(--white-color);
        margin-bottom: 24px;
    }
    &__btn {
        width: 156px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
        @include abstracts.screen(lg) {
            display: flex;
        }
    }
}
#aboutSwiper {
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    @include abstracts.screen(lg) {
        padding-left: initial;
        padding-right: initial;
    }
}
