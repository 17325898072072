@use "../abstracts";

.water {
    &__list {
        margin-top: 60px;
        @include abstracts.screen(md) {
            margin-top: 40px;
        }
        @include abstracts.screen(sm) {
            margin-top: 25px;
        }
    }

    &__item {
        padding: 24px 48px;
        border-radius: 20px;
        background-color: #fff9f2;
        @include abstracts.screen(lg) {
            padding: 20px;
        }
        @include abstracts.screen(md) {
            padding: 16px;
        }
        &:nth-child(even) .water__row {
            flex-direction: row-reverse;
        }
        &:first-child {
            .water__link:hover {
                background-color: #fff;
                color: #eab741;
            }
        }
        &:nth-child(2) {
            background-color: #f2f8fe;

            .water__link {
                background-color: #cce2fd;
                color: #004392;
                &:hover {
                    background-color: #fff;
                    color: #004392;
                }
            }
            .water__content {
                margin-right: auto;
                margin-left: initial;
            }
        }
    }
    &__item + .water__item {
        margin-top: 30px;
    }
    &__content {
        width: fit-content;
        @include abstracts.screen(md) {
            margin: initial;
        }
    }
    &__title {
        font-size: 3rem;
        line-height: 150%;
        font-weight: 600;
        text-transform: uppercase;
        @include abstracts.screen(lg) {
            font-size: 2.4rem;
            margin-top: 20px;
        }
        @include abstracts.screen(md) {
            font-size: 2.4rem;
            margin-top: 20px;
        }
        @include abstracts.screen(sm) {
            font-size: 1.8rem;
        }
    }
    &__desc {
        margin-top: 20px;
        font-size: 2rem;
        line-height: 150%;
        @include abstracts.screen(md) {
            font-size: 1.6rem;
            margin-top: 10px;
        }
    }
    &__link {
        margin-top: 32px;
        display: inline-flex;
        column-gap: 8px;
        border-radius: 99px;
        font-size: 1.4rem;
        font-weight: 600;
        padding: 8px 15px;
        background-color: #ffe7cc;
        color: #995200;
        @include abstracts.screen(md) {
            margin-top: 20px;
        }
    }
    &__thumbs {
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        position: relative;
        padding-top: 60%;
        &-first {
            background-image: url("/sourceshare/source-new/assets/img/waste/waste-01.jpg");
        }
        &-second {
            background-image: url("/sourceshare/source-new/assets/img/water/water-2.jpeg");
        }
    }
    &__img {
        border-radius: 20px;
        width: 100%;
        height: 350px;
        aspect-ratio: 16/9;
        object-fit: cover;
        transition: transform 0.5s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
}
