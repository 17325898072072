@use "../abstracts";

// Table
table {
    border-spacing: 1;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
table * {
    position: relative;
}
table td,
table th {
    padding-left: 8px;
}
table thead tr {
    height: 60px;
    color: var(--color-primary);
    background-color: var(--bg-primary);
}
table tbody tr {
    height: 50px;
}
table tbody {
    max-height: 585px;
    overflow: auto;
}
table tbody tr:last-child {
    border: 0;
}
table td,
table th {
    text-align: left;
}
table td.l,
table th.l {
    text-align: right;
}
table td.c,
table th.c {
    text-align: center;
}
table td.r,
table th.r {
    text-align: center;
}
.table-head th {
    font-size: 1.8rem;
    color: var(--color-primary);
    line-height: 1.2;
    padding-top: 18px;
    padding-bottom: 18px;
}
tbody tr:nth-child(even) {
    background-color: var(--bg-primary);
}
tbody tr {
    font-size: 15px;
    line-height: 1.2;
    font-weight: unset;
}
tbody tr:hover {
    background-color: var(--bg-primary);

    cursor: pointer;
}
tbody td {
    padding-top: 16px;
    padding-bottom: 16px;
}
.column1 {
    width: 260px;
    padding-left: 40px;
}
.column2 {
    width: 160px;
}
.column3 {
    width: 245px;
}
.column4 {
    width: 110px;
    text-align: right;
}
.column5 {
    width: 170px;
    text-align: right;
}
.column6 {
    width: 222px;
    text-align: right;
    padding-right: 62px;
}

@include abstracts.screen(lg) {
    table {
        display: block;
    }
    table > *,
    table tr,
    table td,
    table th {
        display: block;
    }
    table thead {
        display: none;
    }
    table tbody tr {
        height: auto;
    }
    table tbody tr td {
        padding-left: 40% !important;
        margin-bottom: 24px;
    }
    table tbody tr td:last-child {
        margin-bottom: 0;
    }
    table tbody tr td:before {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 600;
        position: absolute;
        width: 40%;
        left: 30px;
    }

    table tbody tr td:nth-child(1):before {
        content: attr(title);
    }
    table tbody tr td:nth-child(2):before {
        content: attr(title);
    }
    table tbody tr td:nth-child(3):before {
        content: attr(title);
    }
    table tbody tr td:nth-child(4):before {
        content: attr(title);
    }
    table tbody tr td:nth-child(5):before {
        content: attr(title);
    }
    table tbody tr td:nth-child(6):before {
        content: attr(title);
    }
    .column4,
    .column5,
    .column6 {
        text-align: left;
    }
    .column4,
    .column5,
    .column6,
    .column1,
    .column2,
    .column3 {
        width: 100%;
    }
    tbody tr {
        font-size: 1.4rem;
    }
}
