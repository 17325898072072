@use "../abstracts";

.technology {
    &__list {
        margin-top: 100px;
        @include abstracts.screen(md) {
            margin-top: 50px;
        }
        @include abstracts.screen(sm) {
            margin-top: 35px;
        }
    }
}
.technology {
    padding: 40px 0;
    &__wrapper {
        margin-top: 40px;
    }
    &__more {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        & .btn {
            width: 120px;
        }
    }
    & .swiper-slide:nth-child(even) .card__inner {
        background: #f7fbff;
    }
}
#slideTech {
    padding-bottom: 50px;
    .swiper-pagination-bullet-active {
        background: var(--color-primary) !important;
        opacity: 1 !important;
    }
    .swiper-pagination-bullet {
        background: #515154;
        opacity: 0.3;
    }
}
