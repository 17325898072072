@use "../abstracts";

.home {
    &__container {
        margin-top: 60px;
        @include abstracts.screen(lg) {
            margin-top: 30px;
        }
        @include abstracts.screen(md) {
            margin-top: 40px;
        }
        @include abstracts.screen(sm) {
            margin-top: 20px;
        }
    }
    &__heading {
        position: relative;
        margin-bottom: 20px;
        font-size: 5rem;
        text-align: center;
        font-weight: 700;
        color: var(--text-title);
        line-height: 141.667%;
        @include abstracts.screen(xl) {
            font-size: 3rem;
        }
        @include abstracts.screen(md) {
            font-size: 2.4rem;
        }
        @include abstracts.screen(sm) {
            font-size: 2.2rem;
        }
        &::after {
            position: absolute;
            content: "";
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            height: 3px;
            background-color: var(--home-separate);
            @include abstracts.screen(sm) {
                width: 30px;
                bottom: -10px;
            }
        }
    }
    &__desc {
        margin-top: 30px;
        font-size: 3rem;
        line-height: 150%;
        color: var(--text-title);

        @include abstracts.screen(xl) {
            font-size: 2.4rem;
        }
        @include abstracts.screen(md) {
            font-size: 1.6rem;
        }
        @include abstracts.screen(sm) {
            font-size: 1.2rem;
            margin-top: initial;
        }
    }
}
