@use "../abstracts";
.section {
    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    &-heading {
        font-weight: 600;
        font-size: 3rem;
        line-height: 24px;
        margin-bottom: 10px;
        @include abstracts.screen(sm) { 
            font-size: 2.4rem;
        }
    }
    &-desc {
        font-size: 1.6rem;
        line-height: 24px;
        text-align: center;
        max-width: 800px;
        margin-top: 12px;
        @include abstracts.screen(sm) { 
            font-size: 1.5rem;
        }
    }
    &-separate {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
    &-line {
        height: 3px;
        width: 70px;
        background-color: var(--primary-color);
    }
    &-water-icon {
        width: 16px;
        height: 16px;
    }
}
