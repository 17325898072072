// Header
@keyframes ctaHeader {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.99) translateY(-0.7em);
        transform-origin: top;
    }
    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}
