.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    height: 40px;
    padding: 6px 12px;
    border-radius: 30px;

    text-align: center;
    background: var(--white-color);

    font-size: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
    transition: 0.3s all ease-in;
    &-primary {
        color: var(--white-color);
        background: var(--primary-color);
        &:hover {
            background: var(--white-color);
            color: var(--primary-color);
            border: 1px solid var(--border-primary);
        }
    }
    &-outline-primary {
        color: var(--primary-color);
        border: 1px solid var(--border-primary);
        background-color: var(--white-color);
        &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
        }
    }
}
