@use "../abstracts";

.waste {
    &__bg {
        padding: 60px 0;
        background-image: url("/sourceshare/source-new/assets/img/waste/water.gif");
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
    }
    &__item--mobile {
        @include abstracts.screen(xl) {
            margin-top: 30px;
        }
    }
    &__row {
        @include abstracts.screen(md) {
            --grid-gutter-y: 30px;
        }
    }
}

.waste__list {
    margin-top: 60px;
    @include abstracts.screen(md) {
        margin-top: 40px;
    }
}

.waste__list article {
    cursor: pointer;
    position: relative;
    display: block;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.waste__heading {
    margin-top: 10px;
    font-size: 2rem;
    font-weight: 600;
    transition: color 0.3s ease-out;
    @include abstracts.screen(md) {
        font-size: 1.6rem;
    }
}
.waste__title {
    margin-top: 10px;
    line-height: 150%;
    @include abstracts.screen(md) {
        font-size: 1.4rem;
    }
}
.waste__list article img {
    max-width: 100%;
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
}

.article-preview {
    padding: 20px;
    background: var(--color-white);
    @include abstracts.screen(md) {
        padding: 12px;
    }
}

.waste__list figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.waste__list figure img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    object-fit: fill;
}

.waste__list article:hover img {
    transform: scale(1.5);
}
