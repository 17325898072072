@use "../abstracts";

.project {
    padding: 40px 0;
    background-color: #f5f6f6;
    &__wrapper {
        margin-top: 30px;
        @include abstracts.screen(sm) {
            margin-top: 15px;
        }
    }
    &__category-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include abstracts.screen(sm) {
            justify-content: space-between;
        }
    }
    &__category-content {
        margin-top: 40px;
    }

    &__img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 250px;
        &:hover .project__content {
            opacity: 1;
            visibility: visible;
        }
    }
    &__thumb {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__content {
        position: absolute;
        inset: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.85);

        transition: all 0.5s ease-in;
        &--project {
            position: initial;
            justify-content: initial;
            align-items: initial;
            opacity: initial;
            visibility: initial;
            background-color: initial;
            flex-direction: column;
            margin-top: 40px;
            .prod-tab__contents {
                padding: initial !important;
            }
        }
        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    &__title {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        color: var(--white-color);
        @include abstracts.screen(sm) {
            font-size: 1.4rem;
        }
    }
    &__desc {
        font-size: 1.4rem;
        color: var(--white-color);
        margin: 10px 0;
        @include abstracts.screen(sm) {
            margin: 4px 0;
        }
    }
    &__more {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        & .btn {
            width: 120px;
        }
    }
}
