@use "../abstracts";
%flexItemsCenter {
    display: flex;
    align-items: center;
}
.header {
    position: sticky;
    top: 0;
    z-index: 99;
    padding-top: 8px;
    background-image: -webkit-linear-gradient(
        0deg,
        #226fe9 0%,
        #098eff 50%,
        #167ff4 74%,
        #226fe9 100%
    );
    @include abstracts.screen(lg) {
        padding: 8px 0px;
    }
    @include abstracts.screen(sm) {
        padding: 12px 0px;
    }
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include abstracts.screen(lg) {
            align-items: end;
        }
    }
    &__intro {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        .btn-outline-primary:hover {
            opacity: 0.8;
        }
    }
    &-intro {
        &-cta {
            display: flex;
            align-items: center;
            gap: 4px;
            @include abstracts.screen(lg) {
                display: none;
            }
        }
        &__hotline,
        &__title {
            color: var(--white-color);
            font-size: 1.4rem;
        }
    }
    &__bar {
        display: flex;
        align-items: center;
    }
    &__img {
        width: 180px;
        @include abstracts.screen(lg) {
            width: 150px;
        }
        &-logo {
            vertical-align: middle;
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(99%) sepia(63%)
                saturate(0%) hue-rotate(129deg) brightness(110%) contrast(100%);
        }
    }
    &__cate {
        position: relative;
        display: flex;
        align-items: center;
        gap: 4px;
        background: #fff;
        padding: 10px 12px;
        margin-left: 30px;
        border-radius: 12px;
        font-size: 1.6rem;
        font-weight: 500;
        color: var(--text-color);
        transition: all 0.3s ease-in;
        z-index: 99;
        &:hover {
            opacity: 0.9;
        }
        &.active {
            color: var(--primary-color);
        }
        &.active .header__cate-icon {
            filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                saturate(3900%) hue-rotate(207deg) brightness(95%) contrast(91%);
        }
    }
    &__search {
        position: relative;
        margin-left: 30px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 50%;
        background: var(--white-color);
        border-radius: 12px;
        border: 1.5px solid transparent;
        padding: 12px;
        height: 44px;
        &:hover,
        &:has(:focus) {
            border: 1.5px solid var(--primary-color);
        }
        &-input {
            width: 100%;
            height: 100%;
            font-size: 1.4rem;
        }
        &-icon {
            width: 24px;
            filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                saturate(3900%) hue-rotate(207deg) brightness(95%) contrast(91%);
        }
        &-list {
            position: absolute;
            display: none;
            width: 100%;
            max-height: 488px;
            overflow-y: auto;
            top: 45px;
            padding: 10px 0;
            border-radius: 5px;
            left: 0;
            background: var(--white-color);
            box-shadow: var(--box-shadow);
            &--show {
                display: block;
            }
            &-items {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            &-item {
                width: 100%;
            }
            &-link {
                display: flex;
                align-items: center;
                padding: 10px;
                gap: 12px;
                width: 100%;
                flex: 1;
                transition: 0.3s all ease-in;
                border-bottom: 1px solid #ededed;
                &:hover {
                    background-color: #f2f8fe;
                }
            }
        }
        &-img {
            width: 80px;
            height: 80px;
            flex-shrink: 0;
        }
        &-thumb {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-product-name {
            font-size: 1.4rem;
            width: 100%;
        }
        &-not-found {
            display: block;
            font-size: 1.4rem;
            text-align: center;
        }
    }
    &__nav {
        display: flex;
        align-items: center;

        &-list {
            display: flex;
            align-items: center;
            @extend %flexItemsCenter;

            @include abstracts.screen(lg) {
                flex-direction: column;
                align-items: flex-start;

                overflow-y: auto;
                overscroll-behavior: contain;
                height: 100%;
                width: 100%;
                padding-bottom: 30px;
            }
        }
        &-item {
            @include abstracts.screen(lg) {
                width: 100%;
                &--active .header__nav-sub-container {
                    display: flex;
                    padding-left: 15px;
                }
                &--active .header__nav-title {
                    color: var(--primary-color);
                }
                &--active .header__nav-arrow {
                    transform: rotate(180deg) !important;
                    filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                        saturate(3900%) hue-rotate(207deg) brightness(95%)
                        contrast(91%) !important;
                }
            }
            &:first-child:hover .header__nav-link img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                    saturate(3900%) hue-rotate(207deg) brightness(95%)
                    contrast(91%);
            }
            &:hover .header__nav-link {
                border-radius: 12px;
                background: var(--white-color);
                color: var(--primary-color);
                @include abstracts.screen(lg) {
                    border-radius: initial;
                    color: initial;
                }
            }
            &:hover .header__nav-arrow {
                transform: rotate(180deg);
                filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                    saturate(3900%) hue-rotate(207deg) brightness(95%)
                    contrast(91%);
                @include abstracts.screen(lg) {
                    transform: rotate(90deg);
                    filter: initial;
                }
            }
            &:hover .header__nav-sub {
                opacity: 1;
                visibility: visible;
                transform: perspective(600px) rotateX(0deg);
            }
            @include abstracts.screen(sm) {
                .header__nav-title {
                    font-size: 1.8rem;
                }
            }
        }
        &-link {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 0px 16px 0 20px;
            border-radius: 12px;
            font-size: 1.4rem;
            font-weight: 500;
            height: 50px;
            line-height: 50px;
            color: var(--white-color);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &-link:first-child {
                padding-left: 20px;
            }
            @include abstracts.screen(lg) {
                color: var(--color-black);
                justify-content: space-between;
                &-logo {
                    padding: 0;
                    height: max-content;
                }
            }
            @include abstracts.screen(sm) {
                &-logo {
                    line-height: initial;
                }
            }
        }
        &-sub {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: #fff;
            padding: 20px 20px;
            border-radius: 5px;
            box-shadow: var(--box-shadow);

            transform: perspective(600px) rotateX(-90deg);
            transform-origin: 0% 0%;
            transition: transform 0.5s ease, opacity 0.6s ease,
                max-height 0.6s step-end, max-width 0.6s step-end,
                padding 0.6s step-end;

            z-index: 999;
            @include abstracts.screen(lg) {
                opacity: 1;
                visibility: visible;
                position: initial;
                padding: 0 20px;
                border-radius: initial;
                box-shadow: initial;
                transform: initial;
                transform-origin: initial;
                transition: initial;
            }
            &-list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
            }
            &-container {
                --grid-gutter-y: 15px;
                @include abstracts.screen(lg) {
                    display: none;
                    flex-direction: column;
                    .col {
                        width: 100%;
                    }
                }
            }
            &-link {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: all 0.3ms ease-in;
                &:hover {
                    color: var(--primary-color);
                    @include abstracts.screen(lg) {
                        color: initial;
                    }
                }
                @include abstracts.screen(sm) {
                    font-size: 1.6rem;
                }
            }
            &-heading {
                color: var(--primary-color);
                font-weight: 500;
            }
        }
        &-arrow {
            transition: 0.3s all ease-in;
            @include abstracts.screen(lg) {
                filter: initial;
                transform: rotate(90deg);
            }
        }
    }
    &__category-tabs {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        height: max-content;
    }
    &__category-content-item {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        padding: 8px;
        box-shadow: var(--box-shadow);
        background: var(--white-color);
        transition: all 0.3s ease-in;
        &:hover .header__category-title {
            color: var(--primary-color);
        }
        &:hover {
            box-shadow: 0 0px 2px 0 #003cbf, 0 0px 6px 0px #003cbf;
        }
    }
    &__category-inner {
        max-height: 380px;
        overflow-y: auto;
    }
    &__category-row {
        --grid-gutter-y: 15px;
    }
    &__category-img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        flex-shrink: 0;
    }
    &__category-thumb {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__category-title {
        --line-clamp: 3;
        font-size: 1.4rem;
        line-height: 1.4;
    }
    &__contact-mobile {
        display: none;
    }
    @include abstracts.screen(lg) {
        &__contact-mobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 8px 16px 0 20px;
            border-top: 1px solid rgba(31, 35, 41, 0.15);
        }
        &__contact-row {
            display: flex;
            align-items: center;
            gap: 4px;
        }
        &__contact-title {
            font-size: 1.4rem;
            color: #5c5c5c;
        }
        &__contact-text {
            font-size: 1.4rem;
            font-weight: 500;
        }
        &__contact-link {
            font-size: 1.4rem;
            color: var(--primary-color) !important;
        }
    }
}

.navbar {
    // Copy
    min-height: initial;
    background-color: initial;
    min-height: initial;
    position: initial;
    z-index: initial;

    @include abstracts.screen(lg) {
        display: flex;
        flex-direction: column;

        position: fixed;
        inset: 0 0 0 50%;
        z-index: 9999;

        margin-left: 0;
        padding: 20px 0;
        height: 100%;
        border-radius: 20px 0px 0px 20px;
        background: var(--sidebar-bg);

        translate: 100%;
        transition: translate 0.5s;

        &.show {
            translate: 0;
            box-shadow: 0px 40px 90px 20px var(--sidebar-shadow);
        }

        &.show ~ .header__overlay &__overlay {
            visibility: visible;
            opacity: 1;
        }
    }

    @include abstracts.screen(sm) {
        inset: 0;
        border-radius: initial;
    }

    &__close-btn {
        display: none;
        font-size: 2rem;
        @include abstracts.screen(lg) {
            display: flex;
            padding: 8px 16px;
        }

        @include abstracts.screen(sm) {
            padding: 0;
        }
    }
    &__bar-btn {
        @include abstracts.screen(lg) {
            margin-right: auto;
        }
        @include abstracts.screen(sm) {
            padding: 8px 20px;
        }
    }
    &__overlay {
        visibility: hidden;
        opacity: 0;

        @include abstracts.screen(lg) {
            position: fixed;
            inset: 0;
            z-index: 8;

            transition: opacity, visibility;
            transition-duration: 0.5s;

            background: rgba($color: #000000, $alpha: 0.4);
        }
    }

    &__item {
        &:hover .dropdown {
            display: block;
        }

        @include abstracts.screen(lg) {
            width: 100%;
            &:hover .dropdown {
                display: none;
            }
            &--active .dropdown {
                display: block !important;
            }
        }
    }

    &__link {
        @extend %flexItemsCenter;
        gap: 6px;
        height: 50px;
        padding: 0 15px;

        font-size: 1.5rem;
        font-weight: 500;
        line-height: 146.667%;

        @include abstracts.screen(lg) {
            justify-content: space-between;
            padding: 4px 8px;
            font-size: 1.6rem !important;
            line-height: 150%;
        }

        @include abstracts.screen(sm) {
            height: 44px;
            padding: 4px 8px;
        }
    }

    &__arrow {
        margin-top: 3px;

        @include abstracts.screen(lg) {
            rotate: -90deg;
            transition: rotate 0.3s;
        }
    }

    &__item--active &__arrow {
        rotate: 0deg;
    }
}
