.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transition: all 0.05s ease-in;
    z-index: 999;
    &__inner {
        background: #fff;
        padding: 20px 20px;
        border-radius: 5px;
        box-shadow: 0 0px 2px 0 rgba(60, 64, 67, 0.1),
            0 0px 6px 0px rgba(60, 64, 67, 0.25);
    }
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}
