@use "../abstracts";
.slider {
    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        & .container {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__img {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 50%;
        @include abstracts.screen(lg) {
            padding: initial;
        }
    }
    &__thumb {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        object-fit: cover;
        @include abstracts.screen(lg) {
            position: relative;
            display: flex;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        overflow: hidden;
        padding: 30px;
        width: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 10;
    }
    &__title {
        position: relative;
        color: var(--white-color);
        font-weight: 600;
        margin-top: 0;
        font-size: 6.4rem;
        text-transform: uppercase;
        text-align: start;
        // &::before {
        //     position: absolute;
        //     content: "";
        //     bottom: -6px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: 100px;
        //     height: 2px;
        //     background-color: var(--primary-color);
        // }
    }
    &__desc {
        color: var(--white-color);
        font-size: 1.8rem;
    }
    &__btn {
        position: absolute;
        padding: 8px 16px;
        height: 60px;
        min-width: 190px;
        font-size: 1.8rem;
        @include abstracts.screen(lg) {
            height: 40px;
            padding: 6px 12px;
            min-width: initial;
            font-size: 1.4rem;
        }
        @include abstracts.screen(sm) {
            height: 30px;
            padding: 8px;
            min-width: initial;
            font-size: 1.2rem;
        }
        &-one {
            left: 18%;
        }
        &-two {
            background-color: #fc6d1f;
            color: #fff;
            left: 11%;
            bottom: 12%;
            &:hover {
                border-color: #fc6d1f;
                color: #fc6d1f;
            }
            &:hover .slider__icon {
                background-color: #fc6d1f !important;
            }
            @include abstracts.screen(xxl) {
                bottom: 8%;
            }
        }
        &-three {
            left: 20%;
            bottom: 18%;
            @include abstracts.screen(xxl) {
                bottom: 14%;
            }
            @include abstracts.screen(xl) {
                height: 50px;
            }
            @include abstracts.screen(lg) {
                height: 40px;
            }
            @include abstracts.screen(md) {
                height: 30px;
                left: 19%;
            }
        }
        &-four {
            left: 50%;
            top: 40%;
            transform: translateX(-50%);
            @include abstracts.screen(md) {
                top: 45%;
            }
        }
        &:hover {
            .slider__icon {
                background-color: var(--color-primary);
                &-img {
                    filter: brightness(0) saturate(100%) invert(99%) sepia(94%)
                        saturate(7%) hue-rotate(99deg) brightness(105%)
                        contrast(100%);
                }
            }
        }
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: var(--color-white);
        box-shadow: 4px 3px 10px -1px rgb(34, 111, 233, 0.4);
        border-radius: 50%;
        transition: 0.3s all ease-in;
        @include abstracts.screen(sm) {
            width: 14px;
            height: 14px;
        }
        &-img {
            width: 16px;
            height: 16px;
            filter: brightness(0) saturate(100%) invert(37%) sepia(73%)
                saturate(3900%) hue-rotate(207deg) brightness(95%) contrast(91%);
        }
    }
}
