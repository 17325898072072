@use "../abstracts";

*,
::before,
::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--text-color);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

a,
img,
button,
input,
label,
textarea,
select {
    padding: 0;
    font-family: inherit;
    color: inherit;
    outline: none;
    border: none;
}

input,
textarea {
    background: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input {
    appearance: none;
    background: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.swiper-button-next,
.swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #515154;
    opacity: 0.3;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    @include abstracts.screen(sm) {
        width: 30px;
        height: 30px;
    }
    &:hover {
        opacity: 0.4;
    }
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 1.8rem;
    color: var(--color-white);
    @include abstracts.screen(sm) {
        font-size: 1.4rem;
    }
}

.swiper-pagination-bullet {
    background-color: var(--color-white);
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background: var(--color-primary);
}
.flex-1 {
    flex: 1 !important;
}
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, 0.2);
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}
.breadcrumb a {
    font-size: 1.6rem;
}
.icon-primary {
    filter: brightness(0) saturate(100%) invert(37%) sepia(73%) saturate(3900%)
        hue-rotate(207deg) brightness(95%) contrast(91%);
}
.icon-white {
    filter: brightness(0) saturate(100%) invert(99%) sepia(94%) saturate(7%)
        hue-rotate(99deg) brightness(105%) contrast(100%);
}
