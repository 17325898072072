@use "../abstracts";
.footer {
    padding: 40px 0 16px 0;
    background: var(--white-color);
    &__media {
        width: 180px;
    }
    &__logo {
        width: 100%;
        height: 100%;
    }
    &__list {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        color: var(--text-color);
        gap: 4px;
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__label {
        font-size: 1.8rem;
        font-weight: 600;
    }
    &__link {
        display: flex;
        width: max-content;
        transition: all 0.05ms ease;
        &:hover {
            color: var(--primary-color);
        }
    }
    &__heading {
        color: var(--primary-color);
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__thank {
        font-size: 1.8rem;
    }
    &__socials {
        display: flex;
        align-items: center;
        width: max-content;
        flex-direction: row;
        gap: 8px;
    }
    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        border-radius: 6px;

        filter: grayscale(50%);
        transition: translate, filter;
        transition-duration: 0.3s;
        &:hover {
            color: #fff;
            filter: grayscale(0);
            translate: 0 -3px;
        }
        &--facebook {
            color: #fff;
            font-size: 2rem;
            background: #4863a9;
        }
        &--zalo {
            background: #f7fbff;
            & .footer__social-icon {
                width: 24px;
            }
        }
    }
    &__copy {
        margin-top: 24px;
        position: relative;
        border-top: 1px solid #e9edf5;
        text-align: center;
    }
    &__copyright {
        padding-top: 20px;
        text-align: center;
        color: rgba(82, 87, 113, 0.6);
        font-size: 1.8rem;
        line-height: 30px;
        @include abstracts.screen(sm) {
            font-size: 1.6rem;
        }
    }
}
