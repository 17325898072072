@use "../abstracts";

.project {
    margin-top: 40px;
    @include abstracts.screen(sm) {
        margin-top: 20px;
    }
    &__inner {
        margin-top: 40px;
        @include abstracts.screen(sm) {
            margin-top: 20px;
        }
    }
    & .hero__img-thumb {
        height: auto;
    }
    &__right {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        flex: 1;
    }
    &__right-thumbs {
        display: flex;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        position: relative;
        padding-top: 50%;
    }
    // &__content {
    //     margin-top: 50px;
    // }
    &__heading {
        border-bottom: 2px solid #a7a7a7;
        & span {
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 24px;
            color: #000000;
            border-bottom: 2px solid #000000;
            display: inline-block;
            padding-bottom: 15px;
            @include abstracts.screen(lg) {
                font-size: 1.8rem;
            }
            @include abstracts.screen(sm) {
                font-size: 1.5rem;
            }
        }
    }
    &-tab {
        margin-top: 8px;
        & .prod-tab__list {
            justify-content: initial;
            margin: initial;
            column-gap: 12px;
        }
        & .prod-tab__item {
            border: 1px solid var(--color-primary);
            border-radius: 8px;
            font-size: 1.4rem;
            color: var(--color-primary);
            @include abstracts.screen(lg) {
                padding: 10px 15px;
            }
            @include abstracts.screen(sm) {
                font-size: 1.3rem;
                padding: 4px 6px;
            }
        }
        & .prod-tab__item--current {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
        & .prod-tab__contents {
            margin-top: 30px;
            background: var(--bg-product-info);
            border-radius: 20px;
            padding: 32px 40px;
            @include abstracts.screen(lg) {
                padding: 24px;
            }
            @include abstracts.screen(sm) {
                padding: 16px;
                margin-top: 20px;
            }
        }
    }
    &__pagination {
        margin-top: 70px;
        display: flex;
        justify-content: end;
        gap: 10px;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 1px solid var(--bg-primary);
        background-color: var(--color-white);
        padding: 8px;
        text-align: center;
        font-size: 14px;
        border-radius: 8px;

        &--active {
            background-color: var(--color-primary);
            color: var(--color-white);
            cursor: default;
            &:hover {
                color: var(--color-white);
            }
            
        }
        &:not(&--active) {
            cursor: pointer;
            &:hover {
                background: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}
