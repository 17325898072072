@use "../abstracts";

.product {
    margin-top: 40px;
    &__right {
        padding: 60px;
        border-radius: 10px;
        background: var(--bg-product-info);
        @include abstracts.screen(lg) {
            padding: 30px;
        }
        @include abstracts.screen(sm) {
            padding: 16px;
        }
    }
    &__heading {
        font-size: 3rem;
        font-weight: 600;
        line-height: 138.462%;
    }
    &__brand {
        margin-top: 6px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 150%;
        color: var(--color-primary);
    }
    &__text {
        margin-top: 12px;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 150%;
    }
    &__list {
        list-style-type: disc;
    }
    &__item {
        margin: 16px 0 0 40px;
        font-size: 1.6rem;
        line-height: 150%;

        & + & {
            margin-top: 16px;
        }
    }
    &__bold {
        font-weight: 600;
    }
    &__link {
        &:hover {
            color: var(--color-primary);
        }
    }
    &__right {
        height: 100%;
    }
    &__phone {
        margin-top: 85px;
        padding: 16px;
        display: flex;
        column-gap: 8px;
        justify-content: center;
        align-items: center;
        background: var(--bg-primary);
        color: var(--color-primary);

        border-radius: 50px;
        font-size: 2.2rem;
        font-weight: 600;
        animation: ctaHeader 0.5s infinite alternate;
        transition: all 0.5s;
        &:hover {
            background: var(--color-primary);
            color: var(--color-white);
        }
        @include abstracts.screen(lg) {
            margin-top: 30px;
        }
    }
    &__tabs {
        margin-top: 60px;
    }
    &-card {
        display: flex;
        flex-direction: column;

        height: 100%;
        padding: 16px;
        border-radius: 20px;

        background: var(--product-card-bg);
        box-shadow: 0px 20px 60px 10px var(--product-card-shadow);
        &__title {
            margin-top: 16px;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 150%;
        }
        &__thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.4s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
        }
        &__brand {
            margin-top: 6px;
            color: var(--color-brand);
            font-size: 1.5rem;
            line-height: 146.667%;
        }
    }
    &-card__img-wrap {
        position: relative;
        padding-top: 100%;
        overflow: hidden;
    }
    &-hot__heading {
        margin-top: 60px;
        margin-bottom: 30px;
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 146.667%;
    }
    &__label {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 6px;
        background: #910c0a;
        color: var(--color-white);
        border-radius: 8px;
        & span {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 146.667%;
        }
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 1.8rem;
        background: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiper2 {
        height: 80%;
        width: 100%;
    }

    .mySwiper1 {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .mySwiper1 .swiper-slide {
        width: 25%;
        height: 100%;
        border-radius: inherit;
        border: 3px solid var(--bg-primary);
        opacity: 0.8;
        transition: opacity, border-color;
        transition-duration: 0.3s;
        border-radius: 10px;
        cursor: pointer;
        &:not(.swiper-slide-thumb-active):hover {
            opacity: 1;
            border-color: var(--color-primary);
            cursor: pointer;
        }
    }

    .mySwiper1 .swiper-slide-thumb-active {
        opacity: 1;
        border: 3px solid var(--color-primary);
        border-radius: 10px;
        overflow: hidden;
        cursor: default;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-slide &__thumb-sm {
        min-width: 150px !important;
        height: 90px !important;
        object-fit: contain !important;
        @include abstracts.screen(sm) {
            width: 75px !important;
            height: 70px !important;
            object-fit: contain !important;
        }
    }
}

// customs
.prod-tab {
    &__list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 -30px;
        overflow: auto;
        @include abstracts.screen(sm) {
            margin: initial;
            padding-bottom: 10px;
        }
    }
    &__item {
        padding: 15px 30px;
        font-size: 2.2rem;
        line-height: 145.455%;
        user-select: none;
        white-space: nowrap;
        border-radius: 20px;
        @include abstracts.screen(lg) {
            padding: 8px 10px;
            font-size: 1.5rem;
        }
        @include abstracts.screen(sm) {
            display: flex;
            justify-content: center;
            width: 100px;
            padding: 10px;
            font-size: 1.2rem;
        }
        &:not(&--current) {
            cursor: pointer;
        }

        &--current {
            color: inherit;
            background: var(--bg-primary);
            color: var(--color-primary);
        }
    }

    &__contents {
        padding-top: 30px;
    }

    &__content {
        display: none;

        &--current {
            display: block;
        }
    }
}

.prod-content {
    &__heading {
        margin-bottom: 30px;
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 141.667%;
    }
}
