@use "../abstracts";
.project-detail {
    margin-top: 40px;
    @include abstracts.screen(sm) {
        margin-top: 20px;
    }
    &__info {
        position: sticky;
        top: 66px;
        padding: 16px;
        border-radius: 10px;
        height: fit-content;
        background: var(--bg-product-info);
        @media screen and (max-width: 1400px) {
            margin-top: 20px;
        }
    }
    &__wrap {
        margin-top: 30px;
        display: grid;
        gap: 24px;
        grid-template-columns: 2fr 0.5fr;
        @media screen and (max-width: 1400px) {
            display: block;
        }
    }
    &__image {
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        background: var(--color-black);
        border-radius: 12px;
    }
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: inherit;
    }
    &__thumb-list {
        display: flex;
        margin-top: 15px;
        column-gap: 15px;
        overflow: auto;
    }
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        &-img {
            min-width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: inherit;
            border: 4px solid var(--bg-primary);
            opacity: 0.8;
            transition: opacity, border-color;
            transition-duration: 0.3s;
            @include abstracts.screen(sm) {
                min-width: 80px;
                height: 80px;
            }
            &:not(.img-active):hover {
                opacity: 1;
                border-color: var(--color-primary);
                cursor: pointer;
            }
        }
    }
    &__video {
        display: flex;
        column-gap: 15px;
    }
    &__icon {
        position: absolute;
        font-size: 4rem;
        color: var(--color-black);
    }
    &__text {
        font-size: 1.8rem;
        font-weight: 600;
    }
    &__heading {
        margin-top: 6px;
        font-size: 2rem;
        font-weight: 500;
        line-height: 138.462%;
    }
    &__row + &__row {
        margin-top: 20px;
    }
    &__desc {
        margin-top: 15px;
        margin-left: 15px;
        font-size: 1.6rem;
        line-height: 138.462%;
        list-style: disc;
    }
}
.img-active {
    opacity: 1;
    border-color: var(--color-primary);
}
