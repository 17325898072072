@use "../abstracts";

.tab {
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 12px;
        width: 100%;
        border: 1.5px solid var(--primary-color);
        background: var(--white-color);
        color: var(--primary-color);

        font-weight: 600;
        font-size: 1.6rem;
        transition: all 0.3s ease-in;
        @include abstracts.screen(sm) {
            font-size: 1.3rem;
            padding: 6px;
        }
        &:hover:not(.tab__item--current) {
            background: var(--primary-color);
            color: var(--white-color);
            & .header__category-icon {
                filter: brightness(0) saturate(100%) invert(99%) sepia(94%)
                    saturate(7%) hue-rotate(99deg) brightness(105%)
                    contrast(100%);
            }
        }
        &--current {
            background: var(--primary-color);
            color: var(--white-color);
            cursor: initial;
            & .header__category-icon {
                filter: brightness(0) saturate(100%) invert(99%) sepia(94%)
                    saturate(7%) hue-rotate(99deg) brightness(105%)
                    contrast(100%);
            }
        }
    }
    &__content {
        display: none;
        &--current {
            display: block;
        }
    }
}
