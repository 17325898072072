@use "../abstracts";

.hero {
    &__inner {
        position: relative;
        // height: calc(100vh - 64px);
    }
    &__img-thumb {
        width: 100%;
        height: calc(100vh - 90px);
        object-fit: cover;
        @include abstracts.screen(xl) {
            height: 100%;
        }
        @include abstracts.screen(lg) {
            height: 100%;
        }
        @include abstracts.screen(sm) {
            height: 100%;
        }
    }
    &__media {
        position: relative;
    }
    &__img {
        display: flex;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        position: relative;
        padding-top: 50%;
    }
    &__heading {
        position: relative;
        text-transform: uppercase;
        font-size: 4.8rem;
        line-height: normal;
        &::after {
            position: absolute;
            content: "";
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            height: 3px;
            background-color: var(--home-separate);
            @include abstracts.screen(sm) {
                width: 30px;
                bottom: -10px;
            }
        }
        @include abstracts.screen(xl) {
            font-size: 3rem;
        }
        @include abstracts.screen(md) {
            font-size: 2.4rem;
        }
        @include abstracts.screen(sm) {
            font-size: 1.6rem;
            white-space: nowrap;
            color: var(--color-white);
            font-weight: 600;
        }
    }
    &__desc {
        // text-transform: uppercase;
        margin-top: 30px;
        font-size: 3rem;
        line-height: 150%;
        @include abstracts.screen(xl) {
            font-size: 2.4rem;
        }
        @include abstracts.screen(md) {
            font-size: 1.6rem;
        }
        @include abstracts.screen(sm) {
            font-size: 1.3rem;
            margin-top: 20px;
            color: var(--color-white);
            font-weight: 600;
        }
    }
    &__content {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        z-index: 1;
        text-align: center;
        @include abstracts.screen(lg) {
            top: 30%;
        }
        @include abstracts.screen(sm) {
            top: 40%;
        }
    }
}
.carousel {
    min-height: 80vh;
    display: grid;
    grid-template-rows: 1fr 3fr 0.5fr;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 50px;
    row-gap: 2vh;
    position: relative;
    z-index: 1;
    @include abstracts.screen(lg) {
        min-height: 80vh;
    }
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    &__fill {
        width: 0;
        height: inherit;
        background: var(--color-primary);
        transition: all 0.16s;
    }
    &--primary {
        z-index: 2;
    }
}

.main-post-wrapper {
    grid-row: 1 / 4;
    grid-column: 1 / 7;

    position: relative;
}
.slides {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.main-post {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: fill;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#0e0d0e, 0.5);
        }
    }
    &__img {
        display: flex;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bg-primary);
        height: 100%;
    }
    &__content {
        position: absolute;
        top: 40%;
        left: 4%;
        transform: translateY(-40%);
        color: var(--color-white);
        width: 90%;
    }
    &__tag-wrapper {
        margin: 0;
        display: inline-flex;
        overflow: hidden;
    }
    &__tag {
        font-size: 0.95em;
        background: var(--color-primary);
        padding: 6px 18px;
    }
    &__title {
        margin-top: 20px;
        font-weight: 700;
        font-size: 2.9rem;
        line-height: 1.4;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        @include abstracts.screen(lg) {
            font-size: 2.6rem;
        }
        @include abstracts.screen(md) {
            font-size: 2.2rem;
        }
        @include abstracts.screen(sm) {
            font-size: 1.8rem;
        }
    }
    &__link {
        margin-top: 30px;
        text-decoration: none;
        color: var(--color-white);
        text-transform: uppercase;
        letter-spacing: 1.5px;
        display: inline-flex;
        column-gap: 8px;
        align-items: center;
        & i:hover {
            color: var(--color-primary);
        }
        &-text {
            font-size: 1.6rem;
        }
        &-icon {
            &--arrow {
                margin-left: 12px;
            }
            &--play-btn {
                margin-right: 12px;
            }
        }
    }
}
.main-post__link:hover i,
.main-post__link:hover .main-post__link-text,
.main-post__link:hover .main-post__link-icon--arrow path {
    color: var(--color-primary);

    stroke: var(--color-primary);
}

.main-post--active {
    top: 0;
    z-index: 1;
    transition: top 0.9s 0.4s ease-out;
}

.main-post--not-active {
    top: 100%;
    z-index: 0;
    transition: top 0.75s 2s;
}

.main-post.main-post--active .main-post__tag-wrapper {
    width: 25%;
    transition: all 0.98s 1.9s;
}
.main-post.main-post--not-active .main-post__tag-wrapper {
    width: 0;
    transition: width 0.3s 0.2s;
}

.main-post.main-post--active .main-post__title {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.8s 1.42s, transform 0.5s 1.4s;
}

.main-post.main-post--not-active .main-post__title {
    transform: translateY(40px);
    opacity: 0;
    transition: transform 0.2s 0.35s, opacity 0.5s 0.2s;
}

.main-post.main-post--active .main-post__link {
    opacity: 1;
    transition: opacity 0.9s 2.2s;
}

.main-post.main-post--not-active .main-post__link {
    opacity: 0;
    transition: opacity 0.5s 0.2s;
}

.posts-wrapper {
    grid-row: 3 / 4;
    grid-column: 3 / 6;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 14px;
    z-index: 1;
}

.post {
    background: rgba(#0e0d0e, 0.6);
    opacity: 0.3;
    color: var(--color-white);
    position: relative;
    padding: 16px 20px;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8em;
    }
    &__tag {
        color: #80837e;
    }
    &__title {
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 1.5;
        @include abstracts.screen(xl) {
            font-size: 1.8rem;
        }
    }
    &--active {
        opacity: 1;
        background: rgba(#0e0d0e, 0.6);
        & .post__title {
            font-weight: 500;
            color: var(--color=white);
        }
    }
    &:not(.post--active) {
        pointer-events: none;
    }
}

.hide-on-mobile {
    display: none;
}

@media screen and (min-width: 1024px) {
    .hide-on-mobile {
        display: grid;
    }
    .posts-wrapper {
        grid-column: 2 / 6;
    }
    .hide-on-desktop {
        display: none;
    }
}

@media screen and (min-width: 1440px) {
    .main-post__content {
        width: 45%;
    }
}
