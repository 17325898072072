@use "../abstracts";

.card {
    padding-top: 100px;
    position: relative;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px 0 rgba(0, 41, 87, 0.08);
    color: inherit;
    max-width: inherit;
    &__inner {
        padding: 16px;
        padding-top: 100px;
    }
    &__img {
        width: 100%;
        inset: 0;
        position: relative;
        padding-top: 56.25%;
        @include abstracts.screen(md) {
            padding-top: 38%;
        }
        &-wrapper {
            width: 100%;
            height: 100%;
            inset: 0;
            position: absolute;
        }
        &-thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__heading {
        --line-clamp: 2;
        font-size: 2.4rem;
        font-weight: 600;
    }
    &__info {
        margin-top: 12px;
        padding: 8px;
        background: #f8f8f8;
    }
    &__desc {
        font-size: 1.4rem;
    }
    &__list {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__item {
        font-size: 1.2rem;
        font-weight: 500;
    }
    &__action {
        margin-top: 20px;
        padding: 24px 0px 0px;
        border-top: 1px solid #e9edf5;
    }
}
